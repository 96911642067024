import React from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import { Helmet } from 'react-helmet-async';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import { cellRender } from './cellRender';
import { messageService } from 'src/api/services/message';
import { IDataTablePayload } from 'src/api/types/table';

const service = async (queryParams?: IDataTablePayload) => messageService.getAll(queryParams);

const MessageList: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Teaser | Messages List</title>
            </Helmet>
            <DashboardTitle title={'Messages List'} />
            <DashboardContentWrapper>
                <Table getTableData={service} cellRender={cellRender} />
            </DashboardContentWrapper>
        </>
    );
};

export default MessageList;
