import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';

const ROUTE_CONSTANTS = {
    ROOT: {
        RELATIVE: '/',
        ABSOLUTE: '/',
    },
    ROOT_STAR: {
        RELATIVE: '/*',
        ABSOLUTE: '/*',
    },
    AUTH: {
        ROOT: {
            RELATIVE: 'auth',
            ABSOLUTE: '/auth',
        },
        LOGIN: {
            RELATIVE: 'login',
            ABSOLUTE: '/auth/login',
        },
    },
    DASHBOARD: {
        ROOT: {
            RELATIVE: 'dashboard',
            ABSOLUTE: '/dashboard',
        },
    },
    PROFILE: {
        ROOT: {
            RELATIVE: 'profile',
            ABSOLUTE: '/profile',
        },
    },
    COMPANIES: {
        ROOT: {
            RELATIVE: 'companies',
            ABSOLUTE: '/companies',
        },
        TYPE: {
            ROOT: {
                RELATIVE: 'companies/:companyType',
                ABSOLUTE: '/companies/:companyType',
            },
            LIST_BY_COMPANY_TYPE: (companyType: COMPANY_TYPE_ROUTE) => ({
                RELATIVE: `companies/${companyType}`,
                ABSOLUTE: `/companies/${companyType}`,
            }),
            SHOW: {
                RELATIVE: 'companies/:companyType/:companyId',
                ABSOLUTE: '/companies/:companyType/:companyId',
                SHOW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                    RELATIVE: `companies/${companyType}/${companyId}`,
                    ABSOLUTE: `/companies/${companyType}/${companyId}`,
                }),
                EDIT: {
                    ROOT: {
                        RELATIVE: 'edit',
                        ABSOLUTE: '/companies/:companyType/:companyId/edit',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/edit`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/edit`,
                        }),
                    },
                },
                PROFILE: {
                    ROOT: {
                        RELATIVE: 'profile',
                        ABSOLUTE: '/companies/:companyType/:companyId/profile',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/profile`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/profile`,
                        }),
                    },
                },
                FINANCIAL_INFORMATION: {
                    ROOT: {
                        RELATIVE: 'financial-information',
                        ABSOLUTE: '/companies/:companyType/:companyId/financial-information',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/financial-information`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/financial-information`,
                        }),
                    },
                },
                FINANCIAL_REPORT: {
                    ROOT: {
                        RELATIVE: 'financial-report',
                        ABSOLUTE: '/companies/:companyType/:companyId/financial-report',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/financial-report`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/financial-report`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'financial-report/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/financial-report/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/financial-report/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/financial-report/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'financial-report/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/financial-report/:id',
                        EDIT_BY_COMPANY_AND_FINANCIAL_REPORT_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, id: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/financial-report/${id}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/financial-report/${id}`,
                        }),
                    },
                },
                FINANCIAL_RATIO: {
                    ROOT: {
                        RELATIVE: 'financial-ratio',
                        ABSOLUTE: '/companies/:companyType/:companyId/financial-ratio',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/financial-ratio`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/financial-ratio`,
                        }),
                    },
                },
                PEOPLE: {
                    ROOT: {
                        RELATIVE: 'people',
                        ABSOLUTE: '/companies/:companyType/:companyId/people',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/people`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/people`,
                        }),
                    },
                    ASSIGN_PERSON: {
                        RELATIVE: 'people/assign-person',
                        ABSOLUTE: '/companies/:companyType/:companyId/people/assign-person',
                        ASSIGN_PERSON_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/people/assign-person`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/people/assign-person`,
                        }),
                    },
                    EDIT_ASSIGNED_PERSON: {
                        RELATIVE: 'people/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/people/:id',
                        ASSIGN_PERSON_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, id: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/people/assign-person`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/people/${id}`,
                        }),
                    },
                },
                DIVIDENDS: {
                    ROOT: {
                        RELATIVE: 'dividends',
                        ABSOLUTE: '/companies/:companyType/:companyId/dividends',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/dividends`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/dividends`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'dividends/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/dividends/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/dividends/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/dividends/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'dividends/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/dividends/:id',
                        EDIT_BY_COMPANY_AND_DIVIDEND_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, id: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/dividends/${id}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/dividends/${id}`,
                        }),
                    },
                },
                SHAREHOLDERS: {
                    ROOT: {
                        RELATIVE: 'shareholders',
                        ABSOLUTE: '/companies/:companyType/:companyId/shareholders',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/shareholders`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/shareholders`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'shareholders/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/shareholders/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/shareholders/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/shareholders/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'shareholders/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/shareholders/:id',
                        EDIT_BY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, shareHolderId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/shareholders/${shareHolderId}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/shareholders/${shareHolderId}`,
                        }),
                    },
                },
                HOLDINGS: {
                    ROOT: {
                        RELATIVE: 'holdings',
                        ABSOLUTE: '/companies/:companyType/:companyId/holdings',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/holdings`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/holdings`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'holdings/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/holdings/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/holdings/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/holdings/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'holdings/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/holdings/:id',
                        EDIT_BY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, holdingId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/holdings/${holdingId}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/holdings/${holdingId}`,
                        }),
                    },
                },
                NEWS: {
                    ROOT: {
                        RELATIVE: 'news',
                        ABSOLUTE: '/companies/:companyType/:companyId/news',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/news`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/news`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'news/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/news/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/news/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/news/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'news/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/news/:id',
                        EDIT_BY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, newsId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/news/${newsId}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/news/${newsId}`,
                        }),
                    },
                },
                PARENT_COMPANY_FINANCIAL: {
                    ROOT: {
                        RELATIVE: 'parent-company-financial',
                        ABSOLUTE: '/companies/:companyType/:companyId/parent-company-financial',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/parent-company-financial`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/parent-company-financial`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'parent-company-financial/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/parent-company-financial/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/parent-company-financial/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/parent-company-financial/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'parent-company-financial/:id',
                        ABSOLUTE: '/companies/:companyType/:companyId/parent-company-financial/:id',
                        EDIT_BY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, id: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/parent-company-financial/${id}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/parent-company-financial/${id}`,
                        }),
                    },
                },
                COMMENTS: {
                    ROOT: {
                        RELATIVE: 'comments',
                        ABSOLUTE: '/companies/:companyType/:companyId/comments',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/comments`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/comments`,
                        }),
                    },
                    SHOW: {
                        RELATIVE: 'comments/:commentId',
                        ABSOLUTE: '/companies/:companyType/:companyId/comments/:commentId',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, commentId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/comments/${commentId}`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/comments/${commentId}`,
                        }),
                    },
                    ADD_NEW: {
                        RELATIVE: 'comments/create',
                        ABSOLUTE: '/companies/:companyType/:companyId/comments/create',
                        ADD_NEW_BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/comments/create`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/comments/create`,
                        }),
                    },
                    EDIT: {
                        RELATIVE: 'comments/:commentId/edit',
                        ABSOLUTE: '/companies/:companyType/:companyId/comments/:commentId/edit',
                        EDIT_BY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number, commentId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/comments/${commentId}/edit`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/comments/${commentId}/edit`,
                        }),
                    },
                },
                FEEDS_CONFIG: {
                    ROOT: {
                        RELATIVE: 'feeds-config',
                        ABSOLUTE: '/companies/:companyType/:companyId/feeds-config',
                        BY_COMPANY_ID: (companyType: COMPANY_TYPE_ROUTE, companyId: number) => ({
                            RELATIVE: `companies/${companyType}/${companyId}/feeds-config`,
                            ABSOLUTE: `/companies/${companyType}/${companyId}/feeds-config`,
                        }),
                    },
                },
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'companies/:companyType/create',
                    ABSOLUTE: '/companies/:companyType/create',
                },
                ADD_NEW_COMPANY_BY_TYPE: (companyType: COMPANY_TYPE_ROUTE) => ({
                    RELATIVE: `companies/${companyType}/create`,
                    ABSOLUTE: `/companies/${companyType}/create`,
                }),
            },
            EDIT_COMPANY: {
                ROOT: {
                    RELATIVE: 'companies/:companyType/:id/edit',
                    ABSOLUTE: '/companies/:companyType/:id/edit',
                },
                EDIT_COMPANY_BY_ID: (companyType: COMPANY_TYPE_ROUTE, companyID: number) => ({
                    RELATIVE: `companies/${companyType}/${companyID}/edit`,
                    ABSOLUTE: `/companies/${companyType}/${companyID}/edit`,
                }),
            },
        },
    },
    PERSONS: {
        ROOT: {
            RELATIVE: 'persons',
            ABSOLUTE: '/persons',
        },
        PEOPLE: {
            ROOT: {
                RELATIVE: 'people',
                ABSOLUTE: '/persons/people',
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'people/create',
                    ABSOLUTE: '/persons/people/create',
                },
            },
            SHOW: {
                ROOT: {
                    RELATIVE: 'people/:id',
                    ABSOLUTE: '/persons/people/:id',
                },
                SHOW_BY_PERSON_ID: (id: number) => ({
                    RELATIVE: `people/${id}`,
                    ABSOLUTE: `/persons/people/${id}`,
                }),
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'people/:id/edit',
                    ABSOLUTE: '/persons/people/:id/edit',
                },
                EDIT_BY_PERSON_ID: (id: number) => ({
                    RELATIVE: `people/${id}/edit`,
                    ABSOLUTE: `/persons/people/${id}/edit`,
                }),
            },
        },
        POSITION: {
            ROOT: {
                RELATIVE: 'positions',
                ABSOLUTE: '/persons/positions',
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'positions/create',
                    ABSOLUTE: '/persons/positions/create',
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'positions/:id',
                    ABSOLUTE: '/persons/positions/:id',
                },
                EDIT_BY_POSITION_ID: (id: number) => ({
                    RELATIVE: `positions/${id}`,
                    ABSOLUTE: `/persons/positions/${id}`,
                }),
            },
        },
    },
    USER_MANAGEMENT: {
        ROOT: {
            RELATIVE: 'user-management',
            ABSOLUTE: '/user-management',
        },
        USERS: {
            ROOT: {
                RELATIVE: 'users',
                ABSOLUTE: '/user-management/users',
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'users/create',
                    ABSOLUTE: '/user-management/users/create',
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'users/:id',
                    ABSOLUTE: '/user-management/users/:id',
                },
                EDIT_BY_USER_ID: (id: number) => ({
                    RELATIVE: `users/${id}`,
                    ABSOLUTE: `/user-management/users/${id}`,
                }),
            },
        },
        USER_REQUEST: {
            ROOT: {
                RELATIVE: 'user-request/:status',
                ABSOLUTE: '/user-management/user-request/:status',
            },
            BY_STATUS: (status: 'pending' | 'approved' | 'canceled') => ({
                RELATIVE: `user-request/${status}`,
                ABSOLUTE: `/user-management/user-request/${status}`,
            }),
        },
        ROLES: {
            ROOT: {
                RELATIVE: 'roles',
                ABSOLUTE: '/user-management/roles',
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'roles/create',
                    ABSOLUTE: '/user-management/roles/create',
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'roles/:id',
                    ABSOLUTE: '/user-management/roles/:id',
                },
                EDIT_BY_ROLE_ID: (id: number) => ({
                    RELATIVE: `roles/${id}`,
                    ABSOLUTE: `/user-management/roles/${id}`,
                }),
            },
        },
    },
    SETTINGS: {
        ROOT: {
            RELATIVE: 'settings',
            ABSOLUTE: '/settings',
        },
        FINANCIAL_INFORMATION: {
            ROOT: {
                RELATIVE: 'financial-information',
                ABSOLUTE: '/settings/financial-information',
            },
            CATEGORIES: {
                ROOT: {
                    RELATIVE: 'categories',
                    ABSOLUTE: '/settings/financial-information/categories',
                },
                UPDATE: {
                    ROOT: {
                        RELATIVE: 'categories/:id',
                        ABSOLUTE: '/settings/financial-information/categories/:id',
                    },
                    EDIT_BY_CATEGORY_ID: (id: number) => ({
                        RELATIVE: `categories/${id}`,
                        ABSOLUTE: `/settings/financial-information/categories/${id}`,
                    }),
                },
                ADD_NEW: {
                    ROOT: {
                        RELATIVE: 'categories/create',
                        ABSOLUTE: '/settings/financial-information/categories/create',
                    },
                },
            },
            SUB_CATEGORIES: {
                ROOT: {
                    RELATIVE: 'sub-categories',
                    ABSOLUTE: '/settings/financial-information/sub-categories',
                },
                UPDATE: {
                    ROOT: {
                        RELATIVE: 'sub-categories/:id',
                        ABSOLUTE: '/settings/financial-information/sub-categories/:id',
                    },
                    EDIT_BY_SUB_CATEGORY_ID: (id: number) => ({
                        RELATIVE: `sub-categories/${id}`,
                        ABSOLUTE: `/settings/financial-information/sub-categories/${id}`,
                    }),
                },
                ADD_NEW: {
                    ROOT: {
                        RELATIVE: 'sub-categories/create',
                        ABSOLUTE: '/settings/financial-information/sub-categories/create',
                    },
                },
            },
            ITEMS: {
                ROOT: {
                    RELATIVE: 'items',
                    ABSOLUTE: '/settings/financial-information/items',
                },
                ADD_NEW: {
                    ROOT: {
                        RELATIVE: 'items/create',
                        ABSOLUTE: '/settings/financial-information/items/create',
                    },
                },
            },
        },
        MEDIA_LIBRARY: {
            ROOT: {
                RELATIVE: 'media-library',
                ABSOLUTE: '/settings/media-library',
            },
        },
        SECTORS: {
            ROOT: {
                RELATIVE: 'sectors',
                ABSOLUTE: '/settings/sectors',
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'sectors/:id',
                    ABSOLUTE: '/settings/sectors/:id',
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `sectors/${id}`,
                    ABSOLUTE: `/settings/sectors/${id}`,
                }),
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'sectors/create',
                    ABSOLUTE: '/settings/sectors/create',
                },
            },
        },
        STOCK_EXCHANGE: {
            ROOT: {
                RELATIVE: 'stock-exchange',
                ABSOLUTE: '/settings/stock-exchange',
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'stock-exchange/:id',
                    ABSOLUTE: '/settings/stock-exchange/:id',
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `stock-exchange/${id}`,
                    ABSOLUTE: `/settings/stock-exchange/${id}`,
                }),
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'stock-exchange/create',
                    ABSOLUTE: '/settings/stock-exchange/create',
                },
            },
        },
        LEGAL_STRUCTURES: {
            ROOT: {
                RELATIVE: 'legal-structures',
                ABSOLUTE: '/settings/legal-structures',
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'legal-structures/:id',
                    ABSOLUTE: '/settings/legal-structures/:id',
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `legal-structures/${id}`,
                    ABSOLUTE: `/settings/legal-structures/${id}`,
                }),
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'legal-structures/create',
                    ABSOLUTE: '/settings/legal-structures/create',
                },
            },
        },
        NEWS_SOURCE: {
            ROOT: {
                RELATIVE: 'news-source',
                ABSOLUTE: '/settings/news-source',
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: 'news-source/create',
                    ABSOLUTE: '/settings/news-source/create',
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: 'news-source/:id',
                    ABSOLUTE: '/settings/news-source/:id',
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `news-source/${id}`,
                    ABSOLUTE: `/settings/news-source/${id}`,
                }),
            },
        },
        FEEDS_CONFIG: {
            ROOT: {
                RELATIVE: 'feeds-config',
                ABSOLUTE: '/settings/feeds-config',
            },
        },
        WIDGETS: {
            ROOT: {
                RELATIVE: 'widgets',
                ABSOLUTE: '/settings/widgets',
            },
        },
        MESSAGES: {
            ROOT: {
                RELATIVE: 'messages',
                ABSOLUTE: '/settings/messages',
            },
        },
    },
    NOTIFICATIONS: {
        RELATIVE: 'notifications',
        ABSOLUTE: '/notifications',
    },
    NOT_FOUND: {
        RELATIVE: 'not-found',
        ABSOLUTE: '/not-found',
    },
    FORBIDDEN: {
        RELATIVE: 'forbidden',
        ABSOLUTE: '/forbidden',
    },
    SENTRY_TEST: {
        RELATIVE: 'sentry-example-page',
        ABSOLUTE: '/sentry-example-page',
    },
};

export default ROUTE_CONSTANTS;
