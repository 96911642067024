import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { RadioButton, RadioButtonProps } from 'primereact/radiobutton';
import { ISelectBox } from 'src/api/types/base-types';
import style from './FormRadio.module.scss';
import { RedError } from 'src/assets/Icons/RedError';
import { useLocation } from 'react-router-dom';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    data?: (ISelectBox & { disabled?: boolean })[];
    className: string;
    errorMessage: FieldError;
    props?: RadioButtonProps & { hidden?: boolean };
}

const FormRadio2 = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    const handleChange = (value: any) => {
        field.onChange(value)
    }
    const { pathname } = useLocation();
    // if user is in edit mode
    const disable = !pathname?.includes('create')
    return (
        <div className={classNames('col-12', style.formFieldRadio2, className ? className : '', props.hidden && 'hidden')}>
            <div className={style.formFieldRadio2Title}>
                <label htmlFor={field.name} className={field.name}>
                    {label} {props.required ? <span className="required">*</span> : ''}
                </label>
                <p>{placeholder}</p>
                {errorMessage ? (
                    <div className={'form-input-error'}>
                        <RedError />
                        <span>{errorMessage.message}</span>
                    </div>
                ) : null}
            </div>
            <div className={style.formFieldRadio2Inputs}>
                {data?.map((radioItem) => (
                    <div key={radioItem.value} className="flex align-items-center mr-3">
                        <RadioButton
                            inputId={String(radioItem.value)}
                            {...field}
                            {...props}
                            onChange={(e) => handleChange(radioItem.value)}
                            checked={field.value === radioItem.value}
                            disabled={radioItem.disabled || disable}
                        />
                        <label htmlFor={String(radioItem.value)} className="ml-2">
                            {radioItem.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FormRadio2;
