/* eslint-disable no-throw-literal */
import { createContext, FC, useCallback, useEffect, useReducer, useState } from 'react';
import Cookies from 'js-cookie';
import { companyService } from 'src/api/services/company';
import { baseRowValueObject, financialInformationLegitFractions } from '../../../../../constants';
import { getAxiosError } from 'src/utils/get-axios-error';
import { ISidePanelStateType } from 'src/types/sidePanel';
import { generateRandomNumber, UUIDv4 } from 'src/utils/uuid-generator';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { BOOLEAN_DICTIONARY } from 'src/constants/booleanDictionary';
import { POPUP_ALERT_TYPES } from 'src/components/Kit/PopupAlert/popup-alert-types';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import ArrayToExpressionParser
    from '../../components/TotalSubtotalValueSelector/components/FormulaComponent/logic/Parsers/ArrayToExpressionParser';
import ExpressionTokenizer
    from '../../components/TotalSubtotalValueSelector/components/FormulaComponent/logic/Tokenizers/ExpressionTokenizer';
import ExpressionToArrayParser
    from '../../components/TotalSubtotalValueSelector/components/FormulaComponent/logic/Parsers/ExpressionToArrayParser';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { truncateDecimal } from 'src/utils/decimal-truncator';
import { IFinancialDateResponse, IFinancialInformationFormStatus, RATIO_NUMBER_TYPE } from 'src/api/types/company';
import {
    FINANCIAL_INFORMATION_ROW_INPUT_TYPE,
    MUTATION_FINANCIAL_STEP,
    NEW_ITEM_POSITION,
    PREVENT_ALERT_SHOWN_LIST,
} from '../../../../../enums';
import {
    DraftDataType,
    IFinancialActiveDate,
    IFinancialDataContext,
    IFinancialInformationRow,
    IItemName,
    ILoadingsType,
    IUncompletedFinancialRows,
    UpdateRowOptions,
} from '../../../../../types';
import {
    FINANCIAL_INFORMATION_STATUS,
    ITEM_TYPE_ENUM,
    ITEM_VALUE_TYPE_OPTIONS_ENUM,
} from 'src/enums/company/financialInformation/financialItem';
import {
    financialActions,
    useCurrentFinancialInformation,
    useFinancial,
    useFinancialCompanyId,
    useFinancialMutationStep,
    useFinancialSelectedType,
} from 'src/context/companies/Companies';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { IParams } from 'src/types/params';
import { checkCompanyType } from 'src/utils/check-company-type';
import { financialInformationService } from 'src/api/services/financial-information-service';
import { bigDataUtils } from 'src/utils/bigDataUtils';
interface IProviderProps {
    children: React.ReactNode;
}

export const FinancialDataContext = createContext<IFinancialDataContext>({} as IFinancialDataContext);
export const FinancialDataProvider: FC<IProviderProps> = ({ children }) => {
    const { companyType } = useParams<IParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const selectedFinancialDataTypeId = useFinancialSelectedType();
    const { activeDate, mustUseDraft } = useFinancial((global) => ({
        activeDate: global.financialActiveDate,
        mustUseDraft: global.mustUseDraft,
    }));
    const step = useFinancialMutationStep();
    const [financialDates, setFinancialDates] = useState<IFinancialActiveDate[]>([]);
    const [existDraftData, setExistDraftData] = useState<boolean>(false);
    const [showSavedDraftNotice, setShowSavedDraftNotice] = useState(false);
    const [financialDataRows, setFinancialDataRows] = useState<IFinancialInformationRow[]>([{ ...baseRowValueObject }]);
    const [accessSaveDraft, setAccessSaveDraft] = useState(false);
    const [financialInformationFormStatus, setFinancialInformationFormStatus] = useState<IFinancialInformationFormStatus>();
    const [uncompletedRows, setUncompletedRows] = useState<IUncompletedFinancialRows[]>([]);
    const [refreshValues, setRefreshValues] = useState<IFinancialInformationRow>(); // Hint: this is for items we dont want to change their values to null cause they are used in some formula.
    const [financialDataDisabledAlert, setFinancialDataDisabledAlert] = useState<{
        show: boolean;
        messages: string[];
        type: POPUP_ALERT_TYPES | undefined;
    }>({
        show: false,
        type: undefined,
        messages: [],
    });
    const [loadings, setLoadings] = useReducer((loadings: ILoadingsType, setLoadings: any) => ({ ...loadings, ...setLoadings }), {
        financialTypesLoading: true,
        financialTypeDataLoading: false,
        applyChangesLoading: false,
        saveLoading: false,
    });
    const [sidePanelState, setSidePanelState] = useState<ISidePanelStateType>({
        type: undefined,
        show: false,
        itemType: undefined,
        itemId: undefined,
    });
    const companyId = useFinancialCompanyId();
    const { financial_information } = useCurrentFinancialInformation()
    const getDraft = (id: number) => {
        return financialInformationService.getDraft(id)
    }

    const financialInformationGetter = () => {
        return financialInformationFormStatus?.id
    }

    const saveDraft = (id: number, data :any) => {
        return financialInformationService.saveDraft(id, data)
    }

    const revokeDraft = (id: number) => {
        return financialInformationService.revokeDraft(id)
    }

    const handleFindMyChildren = (financialRows: IFinancialInformationRow[], uniqueId: string) => {
        let tempChildren: IFinancialInformationRow[] = [];

        const thisRowIsEligibleToBeAChild = (targetRow: IFinancialInformationRow) => {
            const targetRowType: ITEM_TYPE_ENUM | undefined = targetRow.type;
            const targetRowValueType: ITEM_VALUE_TYPE_OPTIONS_ENUM | undefined = targetRow.name && targetRow.name[0]?.value_type;

            return (
                (targetRowType === ITEM_TYPE_ENUM.TOTAL || targetRowType === ITEM_TYPE_ENUM.SUBTOTAL || targetRowType === ITEM_TYPE_ENUM.ITEM) &&
                targetRowValueType === ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC &&
                uniqueId !== targetRow.uniqueId //Hint: Item itself should not include inside list of its children
            );
        };

        financialRows.forEach((targetRow: IFinancialInformationRow) => {
            if (thisRowIsEligibleToBeAChild(targetRow)) tempChildren.push(targetRow);
        });

        return tempChildren;
    };

    const handleFindRelatedItems = (financialRows: IFinancialInformationRow[], targetRow: IFinancialInformationRow) => {
        let tempRelatedItems;

        financialRows.forEach((row: IFinancialInformationRow) => {
            if (typeof row.relatedItems === 'string' && row.uniqueId === targetRow.uniqueId) {
                let tokenizer = new ExpressionTokenizer();
                let tokens = tokenizer.tokenize(row.relatedItems);

                let newTokens = tokens.map((token: string) => {
                    if (token[0] === '{') {
                        let item = JSON.parse(token);
                        if (financialRows.find((row: IFinancialInformationRow) => row.name && row.name[0].id === item.meta.id)) {
                            const targetItem = financialRows.find((row: IFinancialInformationRow) => row.name && row.name[0].id === item.meta.id);
                            item.value = targetItem?.value;
                        }
                        return JSON.stringify(item);
                    } else {
                        return token;
                    }
                });

                const concatenatedTokens = newTokens.join(' ');
                tempRelatedItems = concatenatedTokens;
            }
        });
        return tempRelatedItems;
    };

    const handleCalculatedTotalSubtotalValue = (row: IFinancialInformationRow, relatedItems: string | undefined) => {
        let val = row.value;

        if (row.is_custom_value)
            return row.value; // Hint: if it was custom value just return its value and don't calculate it
        else if (relatedItems) {
            const parser = ExpressionToArrayParser.init();
            let parsedExpression = parser.parseMathExpression(relatedItems);
            let arrayToExpressionParser = new ArrayToExpressionParser();
            let mathExpression = arrayToExpressionParser.generateValue(parsedExpression);

            // eslint-disable-next-line no-eval
            if (mathExpression) val = eval(mathExpression);
        }

        return val;
    };

    const totalSubtotalCalculator = (rows: IFinancialInformationRow[]) => {
        const financialTotalSubtotalCreator = (row: IFinancialInformationRow) => {
            const myRelatedItems = handleFindRelatedItems(rows, row || undefined);
            const myValue = handleCalculatedTotalSubtotalValue(row, myRelatedItems);
            const children = rows.filter((currentRow) => typeof currentRow.value === 'number' && currentRow.uniqueId !== row.uniqueId);

            return {
                ...row,
                // children: children,
                relatedItems: myRelatedItems,
                value: myValue,
            };
        };

        const tempChildren = rows.map((row: IFinancialInformationRow) => {
            switch (row.type) {
                case ITEM_TYPE_ENUM.TOTAL:
                case ITEM_TYPE_ENUM.SUBTOTAL:
                    return financialTotalSubtotalCreator(row);
                default:
                    return row;
            }
        });

        return tempChildren;
    };

    const sanityCheckFormulaOnValueChange = (field: FINANCIAL_INFORMATION_ROW_INPUT_TYPE, value: any, tempRow: IFinancialInformationRow) => {
        if (field !== 'value') return true;

        let updateValueIsValid = true;
        let tokenizer = new ExpressionTokenizer();

        const totalSubtotals =
            financialDataRows &&
            financialDataRows.filter(
                (row: IFinancialInformationRow) => (row.type === ITEM_TYPE_ENUM.TOTAL || row.type === ITEM_TYPE_ENUM.SUBTOTAL) && !row.is_custom_value
            );

        if (!totalSubtotals.length) return true;

        totalSubtotals.forEach((totalSubtotal) => {
            if (totalSubtotal.relatedItems) {
                let tokens = tokenizer.tokenize(totalSubtotal.relatedItems);
                tokens.forEach((token: string) => {
                    if (token[0] === '{') {
                        let item = JSON.parse(token);
                        if (tempRow.name && tempRow.name[0].id === item.meta.id) {
                            if (value === null) {
                                updateValueIsValid = false;
                                return;
                            } else return;
                        } else return;
                    }
                });
            }
        });

        return updateValueIsValid;
    };

    const handleUpdateRow = (
        field: FINANCIAL_INFORMATION_ROW_INPUT_TYPE,
        value: any,
        rowOrder: number,
        numberType?: RATIO_NUMBER_TYPE,
        options?: UpdateRowOptions
    ) => {
        let tempRows = (structuredClone(financialDataRows) || []) as IFinancialInformationRow[];
        let tempRow = tempRows[rowOrder] as IFinancialInformationRow;
        if (handleValidateDuplicatedItem(field, value)) return; // OK: this function does not create performance issue.
        if (!sanityCheckFormulaOnValueChange(field, value, tempRow)) {
            // Hint: sanity check to make sure no formula will get corrupted. and if new value causes some issue we will reset its value to previous one which was safe.
            setRefreshValues(tempRow || null);
            return;
        }

        switch (field) {
            case FINANCIAL_INFORMATION_ROW_INPUT_TYPE.NAME:
                tempRow['must_show_currency'] = numberType === RATIO_NUMBER_TYPE.CURRENCY; //Hint: It detect to show currency prefix box beside for numeric items input or not
                tempRow['number_type'] = numberType || RATIO_NUMBER_TYPE.CURRENCY; //Hint: It detect to show currency prefix box beside for numeric items input or not
                if (tempRow['name']) {
                    if (value.length === 0) {
                        tempRow['name'] = undefined;
                        tempRow['value'] = null;
                    } else if (tempRow['name'] !== undefined) {
                        const targetValue = value[value.length - 1];
                        tempRow['name'][0] = targetValue;
                        switch (targetValue.value_type) {
                            case ITEM_VALUE_TYPE_OPTIONS_ENUM.STRING:
                                tempRow['value'] = '';
                                break;
                            case ITEM_VALUE_TYPE_OPTIONS_ENUM.NUMERIC:
                                tempRow['value'] = null;
                                break;
                        }
                    }
                } else {
                    tempRow['name'] = value;
                }
                break;
            case FINANCIAL_INFORMATION_ROW_INPUT_TYPE.TYPE:
                tempRow['type'] = value;
                tempRow['name'] = undefined;
                tempRow['value'] = null;
                break;
            case FINANCIAL_INFORMATION_ROW_INPUT_TYPE.IS_CUSTOM_VALUE:
                if (!value) {
                    // tempRow.children = handleFindMyChildren(tempRows, tempRow.uniqueId); // Hint: if value is not true it means we are creating some formula and we need children.
                }
                // tempRow.relatedItems = undefined // Hint: when we are changing total or subtotal value type to is_custom_value or formula, we should purge formula. // we should do it with confirmation or do not purge it.
                tempRow[field] = value;
                tempRow.notSaved = true;
                break;
            case FINANCIAL_INFORMATION_ROW_INPUT_TYPE.FIND_MY_CHILDREN:
                // tempRow.children = handleFindMyChildren(tempRows, tempRow.uniqueId);
                break;
            default:
                tempRow[field] = value;
                tempRow.notSaved = true;
                break;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        if (field === FINANCIAL_INFORMATION_ROW_INPUT_TYPE.VALUE) {
            if (options?.submittingCustomValue) {
                tempRow['is_custom_value'] = true;
                tempRow['relatedItems'] = [];
            }
            // Hint: if any type added we should iterate on all items to create and calc totals and subtotals.
            for (let idx = 0; idx < tempRows.length; idx++) {
                tempRows = totalSubtotalCalculator(tempRows);
            }
        }
        setAccessSaveDraft(true);
        setFinancialDataRows(tempRows);
    };

    const handleGetRowsFromApi = async (typeId: number | undefined, activeDate: IFinancialActiveDate) => {
        if (mustUseDraft) {
            const fInfoId = financial_information.id
            if (!fInfoId) {
                return
            }
            // const prevStringDraftData = localStorage.getItem(FINANCIAL_INFORMATION_DRAFT) || '[]';
            const response = await getDraft(fInfoId)
            const prevStringDraftData = response.data.data || []
            const prevDraftData = prevStringDraftData as DraftDataType[];
            const existData = prevDraftData.find(
                (item: DraftDataType) =>
                    item.companyId === Number(companyId) &&
                    item.selectedFinancialDataTypeId === selectedFinancialDataTypeId &&
                    item.date?.year === activeDate?.year &&
                    item.date?.quarter === activeDate?.quarter
            );

            if (existData) {
                setFinancialDataRows(existData.financialDataRows);
                return;
            }
        }

        const handleManipulateFormula = (formula: string) => {
            const JSONFormula = JSON.parse(formula);
            const translator = new ArrayToExpressionParser();
            return translator.generateValue(JSONFormula, 'context') || undefined;
        };

        try {
            financialActions.setLoadings({ financialRowsByDate: true });
            let rowsResponse = await companyService(companyType as COMPANY_TYPE_ROUTE).getRows(companyId, typeId, activeDate);
            financialActions.setCurrentFinancialInfo({
                ...rowsResponse.data.data,
                // todo: temp
                values: []
            })
            let tempRows = [] as IFinancialInformationRow[];
            setFinancialInformationFormStatus(rowsResponse.data.data.financial_information);

            for (let row of rowsResponse.data.data.values) {
                const tempRow: IFinancialInformationRow = {
                    itemId: row.itemId,
                    type: row.type,
                    value: row.value,
                    name: [
                        {
                            id: row.itemId,
                            name: row.name,
                            value_type: row.value_type,
                            category_name: row.category_name,
                            sub_category_name: row.sub_category_name,
                        },
                    ],
                    calculated: BOOLEAN_DICTIONARY[row.calculated],
                    uniqueId: UUIDv4(),
                    is_custom_value: BOOLEAN_DICTIONARY[row.is_custom_value || 0],
                    number_type: row.number_type,
                    ...(row?.formula && { relatedItems: handleManipulateFormula(row.formula) }),
                };

                tempRows = [...tempRows, tempRow];
            }

            tempRows = tempRows.map((row: IFinancialInformationRow) => {
                const tempRow = {
                    ...row,
                    ...((row.type === ITEM_TYPE_ENUM.TOTAL || row.type === ITEM_TYPE_ENUM.SUBTOTAL) && {
                        // children: handleFindMyChildren(tempRows, row.uniqueId),
                        children: [],
                    }),
                };
                return tempRow;
            });


            if (tempRows.length === 0) tempRows.push({ ...baseRowValueObject }); //Hint: means we have no data and need first one.
            financialActions.setLoadings({ financialRowsByDate: false });
            setFinancialDataRows(tempRows);
            return tempRows;
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    const handleApplyChanges = async (fromSave: boolean | undefined) => {
        if (handleValidateSaveData(financialDataRows)) {
            throw new Error('Please complete all fields to save data');
        }

        const relatedItems = (row: IFinancialInformationRow) => {
            // if (!row.children) return; // We should not handle this error, this happens when some title or subtitle somehow has value, not sure how?
            const parser = ExpressionToArrayParser.init();
            let parsedExpression = parser.parseMathExpression(row.relatedItems);
            return parsedExpression;
        };

        const handleMakeValueReady = (value: string | null | number, type: ITEM_TYPE_ENUM) => {
            if (type === ITEM_TYPE_ENUM.TITLE || type === ITEM_TYPE_ENUM.SUBTITLE) return null; // THIS LOGIC IS A SHORT TERM SOLUTION FOR REMOVING VALUES FOR TITLE AND SUBTITLE. THEY SHOULD NEVER HAVE VALUES, AND WEB SERVER SHOULD FIX THIS ISSUE
            if (value === null) return value;
            else {
                if (isNaN(Number(value)))
                    return String(value); // it means value is string type
                else return truncateDecimal(value, financialInformationLegitFractions); // it means value is numeric type
            }
        };

        const tempData = {
            values: financialDataRows.map((row: IFinancialInformationRow) => ({
                item_id: row.name && row.name[0].id,
                value: handleMakeValueReady(row.value, row.type!),
                calculated: row.calculated ? 1 : 0,
                ...((row.type === ITEM_TYPE_ENUM.TOTAL || row.type === ITEM_TYPE_ENUM.SUBTOTAL) && { is_custom_value: row.is_custom_value }),
                ...(!row.is_custom_value && row.relatedItems && row.relatedItems.length > 0 ? { formula: relatedItems(row) } : { is_custom_value: true }),
            })),
            year: activeDate?.year,
            quarter: activeDate?.quarter || null,
        };

        if (tempData.values.length === 0) return; // In case that financial information rows are empty.
        try {
            if (!fromSave) setLoadings({ applyChangesLoading: true });
            let res = await companyService(companyType as COMPANY_TYPE_ROUTE).saveRows(companyId, selectedFinancialDataTypeId, tempData);
            handleSaveRowsLocally();
            setFinancialInformationFormStatus({
                status: financialInformationFormStatus?.status || 1,
                id: res.data.data.id,
            });
            setLoadings({ applyChangesLoading: false });
            removeDraftData({
                companyId: Number(companyId),
                selectedFinancialDataTypeId,
                year: activeDate?.year,
                quarter: activeDate?.quarter || undefined,
                financialInformationId: financialInformationFormStatus?.id
            });
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: 'Your changes applied!',
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoadings({ applyChangesLoading: false });
            throw err;
        }
    };

    const handleSaveRowsLocally = () => {
        // This function change all rows with notSaved = true to notSaved = false which used in validating all items are saved in database
        let tempRows = structuredClone(financialDataRows) || [];

        tempRows = tempRows.map((i: IFinancialInformationRow) => {
            return { ...i, notSaved: false };
        });

        setFinancialDataRows(totalSubtotalCalculator(tempRows)); // Why are we calculating total and subtotal here?
    };

    const handleGetAllDatesApiCall = async (financialTypeId: number | undefined) => {
        const dateRequestParams = {
            ...(financialTypeId && { financialTypeId }),
        };

        try {
            financialActions.setLoadings({
                financialDates: true,
            });
            let datesResponse = await companyService(companyType as COMPANY_TYPE_ROUTE).getAllDates(companyId, dateRequestParams);
            const sortedDates = datesResponse.data.data.sort((a: IFinancialDateResponse, b: IFinancialDateResponse) => {
                if (b.year > a.year) return 1;
                else if (b.year === a.year && b.quarter && a.quarter) {
                    if (b.quarter > a.quarter) return 1;
                    else return -1;
                } else return -1;
            });
            financialActions.addFinancialDates(sortedDates);
            financialActions.setLoadings({
                financialDates: false,
            });
            return sortedDates;
        } catch (error) {
            throw error;
        }
    };

    const handleGetAllDates = async (financialTypeId: number | undefined) => {
        try {
            let dates = await handleGetAllDatesApiCall(financialTypeId);

            let newActiveDate = dates.find((date: IFinancialDateResponse) => date.quarter === activeDate?.quarter && date.year === activeDate.year);
            if (newActiveDate) financialActions.setFinancialActiveDate(newActiveDate);
            setFinancialDates(dates);
        } catch (err) {
            throw err; // We are using this function in another async functions. so I handled errors inside those functions.
        }
    };

    const handleAddNewRow = (previousId: number, position: NEW_ITEM_POSITION) => {
        const newRow: IFinancialInformationRow = {
            itemId: generateRandomNumber(10, 100),
            type: undefined,
            value: null,
            name: undefined,
            calculated: false,
            uniqueId: UUIDv4(),
            notSaved: true,
            is_custom_value: false,
            must_show_currency: true,
            number_type: RATIO_NUMBER_TYPE.CURRENCY,
            // children: financialDataRows,
        };

        setFinancialDataRows((prevState) => {
            return [
                ...prevState.slice(0, position === NEW_ITEM_POSITION.AFTER ? previousId + 1 : previousId),
                newRow,
                ...prevState.slice(position === NEW_ITEM_POSITION.AFTER ? previousId + 1 : previousId),
            ];
        });
    };

    const handleCloseSidePanel = () => {
        setSidePanelState({
            type: undefined,
            show: false,
            itemType: undefined,
            itemId: undefined,
        });
    };

    const handleAccessSaveDraft = (val: any) => {
        setAccessSaveDraft(prevState => val)
    }

    const handleCheckSomeDataNotSaved = () => {
        let tempRows = structuredClone(financialDataRows) || [];

        if (tempRows.find((data: IFinancialInformationRow) => data.type !== undefined && data.notSaved)) return true;
        else return false;
    };

    const handleValidateDuplicatedItem = (field: FINANCIAL_INFORMATION_ROW_INPUT_TYPE, value: IItemName[]) => {
        // OK: this function does not create performance issue.
        let tempRows = structuredClone(financialDataRows) || [];
        if (field === 'name' && value && value.length > 0) {
            if (tempRows.some((row: IFinancialInformationRow) => row.name && row.name[0].id === value[value.length - 1].id)) {
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: 'You can not add duplicated item!',
                });
                return true;
            }
        }
        return false;
    };

    const handleValidateSaveData = useCallback((financialDataRowsToSave : IFinancialInformationRow[]) => {
        let tempUncompletedRows = [] as { uniqueId: string; name?: boolean; value?: boolean }[];

        for (let data of financialDataRowsToSave) {
            let tempUncompletedRow = {} as { uniqueId: string; name?: boolean; value?: boolean };
            if (!data.name) {
                tempUncompletedRow = { uniqueId: data.uniqueId, name: true };
            }
            if (Object.keys(tempUncompletedRow).length > 0) tempUncompletedRows = [...tempUncompletedRows, tempUncompletedRow];
        }

        setUncompletedRows(tempUncompletedRows);
        if (tempUncompletedRows.length > 0) return true;
        else return false;
    }, [])

    const handleCheckAlertVisibility = () => {
        // implement logic of showing alert in here
        if (!checkPermissionAccess(checkCompanyType(PERMISSION_TYPES.COMPANY_FI_EDIT_APPROVED_DATA, companyType as COMPANY_TYPE_ROUTE))) {
            // Hint: this alert should show when you have not edit on approved permission.

            if (activeDate?.status === FINANCIAL_INFORMATION_STATUS.APPROVED) {
                // 1. your are watching approved financial data

                // getting list of "dont show again" approved financial data
                let listOfApprovedFinancialData = [];
                let res = Cookies.get(PREVENT_ALERT_SHOWN_LIST.APPROVED);
                if (res) listOfApprovedFinancialData = JSON.parse(res);

                if (!listOfApprovedFinancialData.find((data: IFinancialInformationFormStatus) => data.id === financialInformationFormStatus?.id)) {
                    // If active date record is not in the list, we should open the alert
                    setFinancialDataDisabledAlert({
                        show: true,
                        type: POPUP_ALERT_TYPES.DANGER,
                        messages: [
                            'You are viewing an "Approved" financial date. Therefore, you cannot change the form and values of the items.',
                            'Contact your system administrator to change this financial date.',
                        ],
                    });
                }
            } else {
                if (!financialDates.find((date) => date.status === FINANCIAL_INFORMATION_STATUS.APPROVED)) {
                    // In this case all dates are not approved and everyone with basic permissions can do anything and nothing will get disabled.
                    return;
                }
                // 2. your are watching not approved financial data which has at least one approved sibling
                let listOfNotApprovedFinancialData = [];
                let res = Cookies.get(PREVENT_ALERT_SHOWN_LIST.NOT_APPROVED);
                if (res) listOfNotApprovedFinancialData = JSON.parse(res);

                if (!listOfNotApprovedFinancialData.find((data: IFinancialInformationFormStatus) => data.id === financialInformationFormStatus?.id)) {
                    // If active date record is not in the list, we should open the alert
                    setFinancialDataDisabledAlert({
                        show: true,
                        type: POPUP_ALERT_TYPES.INFO,
                        messages: [
                            'There is at least one "Approved" financial date in this type. Therefore, you can only change the values of items on this financial date.',
                            'Contact the system administrator to add or remove items.',
                        ],
                    });
                }
            }
        }
    };

    const handleChangeAlertState = (preventShownAgain: boolean) => {
        if (!preventShownAgain) {
            setFinancialDataDisabledAlert({
                show: false,
                type: undefined,
                messages: [],
            });
            return;
        }
        let targetList =
            activeDate?.status === FINANCIAL_INFORMATION_STATUS.APPROVED ? PREVENT_ALERT_SHOWN_LIST.APPROVED : PREVENT_ALERT_SHOWN_LIST.NOT_APPROVED;
        let tempList = [];
        let res = Cookies.get(targetList);
        if (res) tempList = JSON.parse(res);

        tempList.push(financialInformationFormStatus);
        Cookies.set(targetList, JSON.stringify(tempList));
        setFinancialDataDisabledAlert({
            show: false,
            type: undefined,
            messages: [],
        });
    };

    const normalizeDraftDataArray = (dataArray: any) => {
        // this method is only for comparing purposes, don;t use the result of this code
        return dataArray?.map((item: any) => {
            return {
                ...item,
                createdDate: '',
                financialDataRows: normalizeFIArray(item.financialDataRows),
                selectedFinancialDataTypeId: item.selectedFinancialDataTypeId || null,
            }
        }) || []
    };

    const normalizeFIArray = (dataArray: any) => {
        // this method is only for comparing purposes, don;t use the result of this code
        return dataArray?.map((row: any) => {
            return {
                ...row,
                uniqueId: '',
                notSaved: '',
                value: row.value?.toString()
            }
        })
    }
    const saveDraftData = async () => {
        // todo: refactor and enhance performance
        const fInfoId = financialInformationFormStatus?.id || financial_information?.id
        if (!fInfoId){
            return
        }
        // const prevStringDraftData = localStorage.getItem(FINANCIAL_INFORMATION_DRAFT) || '[]';

        const response = await getDraft(fInfoId)
        const prevStringDraftData = response.data.data
        // temp:
        const prevStringDraftDataValuesModified = prevStringDraftData.map((data: any) => {
            return {
                ...data,
                financialDataRows: data?.financialDataRows.map((item : any) => {
                    return {
                        ...item,
                        value: item.value?.toString()
                    }
                })
            }
        })
        const prevDraftData = prevStringDraftDataValuesModified as DraftDataType[];

        const currentData = prevDraftData.find(
            (item: DraftDataType) =>
                item.companyId === Number(companyId) &&
                item.selectedFinancialDataTypeId === selectedFinancialDataTypeId &&
                item.date?.year === activeDate?.year &&
                item.date?.quarter === activeDate?.quarter
        );
        
        if (!(financialDataRows.length > 0)) return;

        const newEntry = {
            ...currentData,
            financialDataRows,
            selectedFinancialDataTypeId,
            companyId: Number(companyId),
            date: activeDate,
            createdDate: new Date().getTime(),
        };

        const newDraftData = currentData
            ? [newEntry]
            : [...prevDraftData, newEntry]

        const normalizedNewDraftData = normalizeDraftDataArray(newDraftData);
        const normalizedPrevDraftData = normalizeDraftDataArray(prevDraftData);

        if (bigDataUtils.isDataEqual(normalizedNewDraftData,
            normalizedPrevDraftData)) {
            return;
        }

        if (accessSaveDraft && step === MUTATION_FINANCIAL_STEP.SELECT_FINANCIAL_DATA) {
            // localStorage.setItem(FINANCIAL_INFORMATION_DRAFT, newStringDraftData);
            await saveDraft(fInfoId, newDraftData)

            setAccessSaveDraft(false);
            setShowSavedDraftNotice(true);
            setTimeout(() => {
                setShowSavedDraftNotice(false);
            }, 1500);
        }
    };

    const removeDraftData = useCallback(async ({
        companyId,
        selectedFinancialDataTypeId,
        year,
        quarter,
        financialInformationId
    }: {
        companyId: number;
        selectedFinancialDataTypeId: number | undefined;
        year: number | undefined;
        quarter: number | undefined;
        financialInformationId: number | undefined;
    }) => {
        const currentId = financialInformationId || financialInformationFormStatus?.id; // Capture the current ID
        if (!currentId) {
            console.error('Financial Information ID is undefined');
            return;
        }

        // const prevStringDraftData = localStorage.getItem(FINANCIAL_INFORMATION_DRAFT) || '[]';
        const response = await getDraft(currentId)
        const prevStringDraftData = response.data.data
        const prevDraftData = prevStringDraftData as DraftDataType[];
        const newDraftData = prevDraftData.filter((item: DraftDataType) => {
            let condition = item.companyId === companyId && item.date?.year === year;
            if (item.selectedFinancialDataTypeId) {
                condition = condition && item.selectedFinancialDataTypeId === selectedFinancialDataTypeId;
            }
            if (item.date?.quarter) {
                condition = condition && item.date.quarter === quarter;
            }
            return !condition;
        });

        // const newStringDraftData = JSON.stringify(newDraftData);
        // localStorage.setItem(FINANCIAL_INFORMATION_DRAFT, newStringDraftData);
        await revokeDraft(currentId)
        setExistDraftData(false);
    }, [financialInformationFormStatus, getDraft])

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         // saveDraftData();
    //     }, FINANCIAL_INFORMATION_DRAFT_TIME);
    //
    //     return () => clearInterval(interval);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [financialDataRows, existDraftData]);

    useEffect(() => {
        handleCheckAlertVisibility();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialInformationFormStatus]);

    const initDraftData = useCallback(async (financialInformationFormStatusId: number) => {
        try {
            const response = await getDraft(financialInformationFormStatusId);
            const prevDraftData = response.data.data as DraftDataType[];

            const currentData = prevDraftData.find(
                (item: DraftDataType) =>
                    item.companyId === Number(companyId) &&
                    item.selectedFinancialDataTypeId === selectedFinancialDataTypeId &&
                    item.date?.year === activeDate?.year &&
                    item.date?.quarter === activeDate?.quarter
            );
            const isDataEqual = bigDataUtils.isDataEqual(normalizeFIArray(currentData?.financialDataRows),
                normalizeFIArray(financialDataRows))
            const hasNewData = currentData && !isDataEqual
            setExistDraftData(mustUseDraft ? false : Boolean(hasNewData));
        } catch (error) {
            console.error("Error fetching draft data:", error);
        }
    }, [selectedFinancialDataTypeId, companyId, activeDate, mustUseDraft, financialDataRows]);

    useEffect(() => {

        const infoID = financialInformationGetter()
        if (infoID) {
            initDraftData(infoID);
        }
    }, [financialInformationFormStatus?.id]);

    useEffect(() => {
        if (financialInformationFormStatus?.id) {
            financialActions.setCurrentFinancialInfo({
                financial_information: {
                    ...financialInformationFormStatus
                },
                // todo: temp
                values: []
            })
        }
    }, [financialInformationFormStatus?.id]);

    useEffect(() => {
        saveDraftData()
        // console.log('financialDataRows', financialDataRows);
    }, [financialDataRows]);

    return (
        <FinancialDataContext.Provider
            value={{
                loadings,
                sidePanelState,
                financialDates,
                financialDataRows,
                financialInformationFormStatus,
                financialDataDisabledAlert,
                uncompletedRows,
                refreshValues,
                totalSubtotalCalculator,
                setRefreshValues,
                setFinancialDataDisabledAlert,
                setFinancialInformationFormStatus,
                setFinancialDates,
                setLoadings,
                setFinancialDataRows,
                setSidePanelState,
                handleCheckSomeDataNotSaved,
                handleGetRowsFromApi,
                handleApplyChanges,
                handleGetAllDates,
                handleUpdateRow,
                handleAddNewRow,
                handleCloseSidePanel,
                handleChangeAlertState,
                handleGetAllDatesApiCall,
                existDraftData,
                setExistDraftData,
                removeDraftData,
                showSavedDraftNotice,
                handleAccessSaveDraft
            }}
        >
            {children}
        </FinancialDataContext.Provider>
    );
};
