import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import { USER_TYPE } from 'src/enums/user/user-type';

export type userSchemaFields = 'full_name' | 'email' | 'username' | 'password' | 'role_id' | 'type'

export type TUserSchema = Record<string, IFormGeneratorSchemaType>

export const userSchema: TUserSchema = {
    full_name: {
        placeholder: 'Full Name',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    email: {
        placeholder: 'Email',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    username: {
        placeholder: 'Username',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    },
    password: {
        placeholder: 'Password',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            type: "password"
        }
    },
    type: {
        placeholder: 'Choose User Type',
        type: FORM_INPUTS.RADIO_2,
        props: {
            required: true
        },
        data:[
            {
                value: USER_TYPE.USER,
                label: 'User'
            },
            {
                value: USER_TYPE.ADMIN,
                label: 'Admin'
            },
        ],
        defaultValue: {
            value: USER_TYPE.USER,
            label: 'User'
        }
    },
    role_id: {
        placeholder: 'Select Roles',
        type: FORM_INPUTS.SELECT,
        props: {
            required: true
        }
    }
};

