import React from 'react';
import style from 'src/pages/Dashboard/HomePage.module.scss';

const Dashboard: React.FC = () => {
    return (
        <>
            <div className={style.dashboardTitle}>
                <div className={style.headerContent}>
                    <h1>
                        Welcome to the Admin Dashboard !
                    </h1>
                    <div className={style.description}>
                        <p>Our role is crucial in ensuring accurate and up-to-date financial data for top companies. </p>
                        <p>Together, we manage entries, verify financial data, and maintain the quality of insights provided to our users.</p>
                        <p>Thank you for helping us keep the platform’s data reliable and comprehensive!</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
