import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router-dom';
import ROUTE_CONSTANTS from './Routes/constants/route-constants';
import React from 'react';

Sentry.init({
    dsn: 'https://a8864c6e8cac00788de1e092b8eaf3f3@o4506291824820224.ingest.us.sentry.io/4508120489590784',
    ignoreErrors: [
        'Network Error',
        'timeout of 10000ms exceeded',
        'Request aborted',
        // 'e.focus is not a function'// I added this as it happens when some
        // mandatory dropdown in forms are not filled and admin
        // trigger submit button and form wants to be make a focus on that dropdown, but it can not.
    ],
    integrations: [
        Sentry.browserApiErrorsIntegration({
            setTimeout: true,
            setInterval: true,
            requestAnimationFrame: true,
            XMLHttpRequest: true,
            eventTarget: true,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.breadcrumbsIntegration({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true,
        }),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error']
        }),
        Sentry.reportingObserverIntegration(),
        Sentry.sessionTimingIntegration(),

        //     new Sentry.Feedback({
        //         colorScheme: "light",
        //
        //       }),
    ],
    debug: false,
    // tracesSampleRate: 0.2,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    sampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
        <App />
    </BrowserRouter>
)