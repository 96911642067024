import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '..';
import { IDataTablePayload, IDataTableResponse } from '../types/table';
import { IMessagesResponse } from '../types/messages';

export const messageService = {
    getAll(data?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IMessagesResponse>>(API_ROUTES.MESSAGES.GET_ALL, { params: data });
    },
};
